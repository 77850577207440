<template>
  <v-container id="whoweare" class="ma-auto" fluid>
    <h1 class="font-weight-medium custom-secondary--text text-uppercase text-center text-md-left">{{$t("who")}} <font class="custom-accent--text">{{$t("we")}}</font> {{$t("are")}}</h1>
    <div class="mt-6 custom-secondary--text">
      <p>
      {{$t("whowearedesc1")}}
      </p>
      <p>
      {{$t("whowearedesc2")}}
      {{$t("whowearedesc3")}}
      </p>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "WhoWeAre",
  data() {
    return {};
  },
  components: {},
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
 
